import * as React from "react"
import { graphql } from "gatsby"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import clsx from "clsx"
import { isFilled } from "@prismicio/helpers"

import { BoundedBox } from "../components/BoundedBox"
import { RichText, HEADING_CLASSES } from "../components/RichText"
import { UnderlineLink } from "../components/UnderlineLink"
import { Text } from "../components/Text"

import type { PageDataBodyFormFragment } from "../graphql.gen"
import { Button } from "../components/Button"
import { V } from "../components/V"
import { useLoadGoogleRecaptcha } from "../hooks/useLoadGoogleRecaptcha"
import { RECAPTCHA_SITE_KEY } from "../constants"

interface FieldProps {
	label?: string
	required?: boolean
}

interface FieldShellProps
	extends Pick<FieldProps, "label">,
		Pick<TextFieldProps, "fullWidth"> {
	children: React.ReactNode
}

const FieldShell = ({
	label,
	children,
	fullWidth = false,
}: FieldShellProps) => {
	return (
		<label className={clsx("space-y-3.5", fullWidth && "md:col-span-full")}>
			{label && <Text variant="formLabel">{label}</Text>}

			{children}
		</label>
	)
}

interface TextFieldProps extends FieldProps {
	fullWidth?: boolean
}

const TextField = ({ label, required, fullWidth }: TextFieldProps) => {
	return (
		<FieldShell label={label} fullWidth={fullWidth}>
			<input
				type="text"
				required={required}
				className="w-full border-[3px] border-black focus:border-red focus:ring-0 transition"
				name={label}
			/>
		</FieldShell>
	)
}

const TextareaField = ({ label, required }: FieldProps) => {
	return (
		<FieldShell fullWidth label={label}>
			<textarea
				required={required}
				className="border-[3px] border-black w-full min-h-[300px] md:min-h-[170px] focus:border-red focus:ring-0 transition"
				name={label}
			/>
		</FieldShell>
	)
}

type FormProps = React.ComponentPropsWithoutRef<"form"> &
	Pick<
		Props,
		"fields" | "successHeading" | "successLinks" | "successText" | "formUid"
	>

type FormState = "submitting" | "entry" | "submitted" | "error"

const Form = ({
	className,
	formUid,
	fields,
	successHeading,
	successLinks,
	successText,
	...props
}: FormProps) => {
	const [state, setState] = React.useState<FormState>("entry")
	const shouldShowFormElement = ["entry", "submitting", "error"].includes(state)

	async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault()

		setState("submitting")

		try {
			const token = await grecaptcha.execute(RECAPTCHA_SITE_KEY, {
				action: "submit",
			})
			const formData = {
				formUid,
				token,
				// @ts-expect-error FormData instances are iterable objects
				...Object.fromEntries(new FormData(e.target as HTMLFormElement)),
			}

			const res = await fetch("/api/form", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(formData),
			})
			if (res.status >= 300) {
				return setState("error")
			}

			const data = await res.json()
			if (data.success) return setState("submitted")
		} catch (err) {
			console.warn(err)
			setState("error")
		}
	}

	return (
		<>
			{!shouldShowFormElement && (
				<div className={className}>
					<div className="flex items-center justify-center bg-black">
						<div
							className={clsx(
								"flex items-center justify-center",
								"rounded-full bg-red",
								"w-[104px] h-[104px] lg:w-[120px] lg:h-[120px]",
								"relative",
								"-top-3 lg:-top-8 mb-2.5 lg:mb-0",
							)}
						>
							<V
								leftFillClassName="fill-black"
								rightFillClassName="fill-white"
								className="w-[56px] lg:w-16"
							/>
						</div>
					</div>

					<div
						className={clsx(
							"bg-gray-93",
							"py-9 lg:py-10 2xl:py-12",
							"px-[26px] lg:px-[50px]",
						)}
					>
						{successHeading && (
							<Text
								asChild
								variant="formSuccessHeading"
								className="max-w-[470px]"
							>
								<p>{successHeading}</p>
							</Text>
						)}

						{successText && (
							<Text asChild variant="formSuccessText" className="mt-6 lg:mt-8">
								<p>{successText}</p>
							</Text>
						)}

						{successLinks.length > 0 && (
							<div className="flex flex-col items-start mt-8 lg:mt-10 space-y-9">
								{successLinks.map((link) => (
									<UnderlineLink href={link.href} key={link.text}>
										{link.text}
									</UnderlineLink>
								))}
							</div>
						)}
					</div>
				</div>
			)}

			{shouldShowFormElement && (
				<form
					onSubmit={handleSubmit}
					className={clsx(
						"bg-gray-93",
						"py-9 lg:py-10 2xl:py-12",
						"px-[26px] lg:px-[50px]",
						"grid",
						"gap-y-8 md:gap-y-9",
						"gap-x-6",
						"md:grid-cols-2 transition",
						state === "submitting" && "opacity-25 pointer-events-none",
						className,
					)}
					{...props}
				>
					{fields?.map((field) => {
						switch (field.__typename) {
							case "PrismicFormDataBodyTextField":
								return (
									<TextField
										key={field.primary.label?.text}
										label={field.primary.label?.text}
										required={field.primary.required}
										fullWidth={field.primary.full_width}
									/>
								)

							case "PrismicFormDataBodyTextareaField":
								return (
									<TextareaField
										key={field.primary.label?.text}
										label={field.primary.label?.text}
										required={field.primary.required}
									/>
								)

							default:
								return null
						}
					})}

					{state === "error" && (
						<Text
							asChild
							variant="paragraph1"
							className="col-span-2 font-semibold text-red"
						>
							<p>Sorry something went wrong, please try again.</p>
						</Text>
					)}

					<Button
						color="black"
						className={clsx(
							"mr-auto min-w-[200px] md:col-span-2",
							state === "error" && "-mt-5",
						)}
						disabled={state === "submitting"}
					>
						Send
					</Button>
				</form>
			)}
		</>
	)
}

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyForm = ({
	text,
	textLinks,
	formUid,
	fields,
	successHeading,
	successLinks,
	successText,
}: Props) => {
	useLoadGoogleRecaptcha()
	const hasColumns = isFilled.richText(text) || textLinks.length > 0

	return (
		<BoundedBox.Outer className="bg-white">
			<BoundedBox.Inner
				className={clsx(
					"grid",
					hasColumns && "lg:grid-cols-[22.5rem,1fr] lg:gap-x-20",
					"gap-y-11 lg:gap-y-0",
				)}
			>
				{hasColumns && (
					<div>
						{isFilled.richText(text) && (
							<RichText
								field={text}
								componentOverrides={{
									heading3: (props) => (
										<Text
											asChild
											variant="heading3"
											className={HEADING_CLASSES}
											uppercase
										>
											<h3>{props.children}</h3>
										</Text>
									),

									paragraph: (props) => (
										<Text
											asChild
											variant="paragraph1"
											uppercase
											className="font-semibold"
										>
											<p>{props.children}</p>
										</Text>
									),
								}}
							/>
						)}

						{textLinks.length > 0 && (
							<div className="flex flex-col items-start mt-14 space-y-9">
								{textLinks.map((link) => (
									<UnderlineLink key={link.text} href={link.href}>
										{link.text}
									</UnderlineLink>
								))}
							</div>
						)}
					</div>
				)}

				<Form
					formUid={formUid}
					className={clsx(
						"-mx-[26px] lg:mx-0",
						"md:max-w-3xl md:w-full",
						"md:mx-0",
						hasColumns && "lg:mx-auto",
					)}
					fields={fields}
					successHeading={successHeading}
					successLinks={successLinks}
					successText={successText}
				/>
			</BoundedBox.Inner>
		</BoundedBox.Outer>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyFormFragment>) {
	const primary = data.primary
	const formDoc = data.primary.form?.document
	if (formDoc && formDoc.__typename !== "PrismicForm") {
		throw new Error("Did not receive Form document when queried for!")
	}

	return {
		text: primary.text?.richText,
		textLinks: data.items.map((item) => ({
			href: item.link?.url,
			text: item.link_text,
		})),
		formUid: formDoc?.uid,
		fields: formDoc?.data.body,
		successHeading: formDoc?.data.success_heading?.text,
		successText: formDoc?.data.success_text?.text,
		successLinks:
			formDoc?.data.success_links?.map((link) => ({
				href: link?.link?.url,
				text: link?.link_text,
			})) ?? [],
	}
}

export const fragment = graphql`
	fragment TextareaField on PrismicFormDataBodyTextareaField {
		primary {
			label {
				text
			}
			required
		}
	}

	fragment TextField on PrismicFormDataBodyTextField {
		primary {
			label {
				text
			}
			required
			full_width
		}
	}

	fragment PageDataBodyForm on PrismicPageDataBodyForm {
		primary {
			form {
				document {
					__typename
					... on PrismicForm {
						_previewable
						uid
						data {
							body {
								__typename
								...TextField
								...TextareaField
							}
							success_heading {
								text
							}
							success_text {
								text
							}
							success_links {
								link_text
								link {
									url
								}
							}
						}
					}
				}
			}
			text {
				richText
			}
		}
		items {
			link {
				url
			}
			link_text
		}
	}
`

export default PageDataBodyForm
